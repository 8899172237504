.c-container{
    justify-content: space-between;
    margin-top: 2.2rem;
}
.c-container>div{
    flex: 1;
    margin-top: 1.2rem;
}
.c-right{
    width: 100%;
}
.c-left{
    gap: .5rem;
    margin-left: 5rem;
}
.contactModes{
    margin-top: 2rem;
    gap: 1rem;
    /* border: 2px solid black; */
}
.contactModes .row{
    gap: 1.5rem;
}
.mode{
    width: 16rem;
    padding: 1rem;
    border: 0.3rem solid rgba(128, 128, 128, 0.143);
    border-radius: 5px;
    gap: 1rem;
    transition: all 300ms ease-in;
}
.mode .button{
    width: 100%;
    background: var(--lightBlue);
    color: var(--blue);
    font-size: .9rem;
    font-weight: 600;
}
.mode >:nth-child(1){
    display: flex;
    justify-content: space-between;
    gap: 1.6rem;
}
.mode .detail .primaryText{
    font-size: 1.1rem;
    font-weight: 600;
}
.mode:hover{
    scale: 1.1;
    box-shadow: var(--shadow);
}
.mode .button:hover{
    background: var(--blue-gradient);
    color: white;
    scale: 0.8;
}
.second{
    position: relative;
    left: 23rem;
    top: 4rem;
   
}
.fourth{
    position: relative; 
    left:18rem ;
    bottom: 9.6rem;
}
@media (max-width: 500px) {
    .second{
        position: static;
        top: 135rem;
    }
    .fourth{
        position: static;
        bottom: 11rem;
    }
    .img-container{
        position: relative;
        /* right: 2srem; */
        left: .4rem;
        width: 23rem;
    }
}