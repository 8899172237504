.f-wrapper{
    background:black;
    margin-top: 2.4rem;
    /* margin-bottom: 3em; */
    height: 15rem;
}
.f-container{
    justify-content: space-between;
    margin-top: 2.4rem;
}
.f-left{
    gap: 1rem;
    margin-left: 4.8rem;
    margin-top: 2em;
}
.f-right{
    margin-right: 4.5rem;
    margin-top: 3rem;
}
.f-menu{
    gap: 1.5rem;
    margin-top: 1.5rem;
    font-weight: 500;
}
.head{
    color: aquamarine;
}
.Text{
    color: azure;
}

@media (max-width: 500px){
    .f-wrapper{
        margin-top: 1.5rem;
        height: 50rem;
    }
    .f-left{
        margin-left: 3rem;
        margin-top: 2rem;
    }
    .f-right{
        margin-left: 3rem;
        margin-bottom: 4rem;
    }

}